import { useRouter } from 'next/router';
import React from 'react';

import Layout from '../components/Layout';
import { Seo } from '../components/meta/Seo';
import RenderSections from '../components/sections/render-sections';
import { getClient } from '../lib/sanity';
import { getConfigQuery } from '../queries/config';
import { getPageQuery } from '../queries/page';
import { getSitemapQuery } from '../queries/sitemap';

const SlugPage = ({ config, page, sitemapItem, preview, sitemap }) => {
  const router = useRouter();
  const pagePath = router.asPath;

  if (!page || router.isFallback) return <Layout config={config} loading={true} />;

  return (
    <Layout
      config={config}
      sitemap={sitemap}
      preview={preview}
      reloadPreview={() => {}}
      pagePath={pagePath}
      sitemapItem={sitemapItem}
      pageNavigation={page.navigation}
    >
      <Seo
        page={page}
        config={config}
        sitemap={sitemap}
        preview={preview}
        sitemapItem={sitemapItem}
      />

      {page?.sections && (
        <RenderSections
          sections={page.sections}
          document={page}
          page={page.title}
          pageType={config.type}
          type={sitemapItem.pageType || sitemapItem._type}
          slug={{ current: sitemapItem.path }}
        />
      )}
    </Layout>
  );
};

export default SlugPage;

/**
 * Get static props:
 * - sitemap for resolving all links
 * - page
 */

export const getStaticProps = async ({ params, preview = false }) => {
  const { slug = '' } = params;
  const path = Array.isArray(slug) ? `/${slug.join('/')}` : `/${slug}`;

  // fetch sitemap
  const flatSitemap = await getClient(preview).fetch(getSitemapQuery());
  const sitemap = flatSitemap
    .flat()
    .filter(Boolean)
    .filter((item) => Boolean(item.path));

  if (!sitemap.filter(Boolean).length) return { notFound: true };

  // get draft page or published page in preview mode
  const sitemapItem = preview
    ? sitemap.find((item) => item.path === path && item._id.startsWith(`drafts.`)) ||
      sitemap.find((item) => item.path === path)
    : // get published page in production mode
      sitemap.find((item) => item.path === path);

  if (!sitemapItem) return { notFound: true };

  // fence corporate / consumer pages
  if (sitemapItem.path.startsWith('https://')) return { notFound: true };

  // fetch config
  const config = await getClient(preview).fetch(
    getConfigQuery(process.env.NEXT_PUBLIC_SCOPE),
  );

  // fetch page
  const page = await getClient(preview).fetch(
    getPageQuery(sitemapItem.pageId || sitemapItem._id, sitemapItem._type),
    {
      ...sitemapItem,
      sitemap,
    },
  );

  if (!page) return { notFound: true };

  // return object
  const props = {
    config,
    page,
    preview,
    sitemap,
    sitemapItem,
  };

  return { props, revalidate: 10 };
};

/**
 * Static paths
 */

export const getStaticPaths = async () => {
  const sitemap = await getClient(false).fetch(
    getSitemapQuery(process.env.NEXT_PUBLIC_SCOPE),
  );

  return {
    paths: sitemap
      .flat()
      .filter(Boolean)
      .filter((item) => Boolean(item.path))
      .map(({ path }) => ({
        params: { slug: path.split('/').splice(1) },
      })),
    fallback: true,
  };
};
